@import "react-date-range/dist/styles.css";
@import "react-date-range/dist/theme/default.css";

.basket {
  &-holder {
    width: 100%;
    display: flex;
    min-height: calc(100vh - 120px);
  }
  &-content {
    flex: 3;
    display: flex;
    flex-direction: column;
    background-color: content;
    max-height: calc(100vh - 120px);
    border-radius: 0px;
    overflow: hidden;
    position: relative;
    .products {
      &-list {
        flex: 1;
        max-height: 75%;
        overflow-y: scroll;
        padding: 24px;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none;
        }
      }
      &-price {
        // background-color: #d9d9d9;
        border-radius: 0px;
        // padding: 10px;
        margin-left: 24px;
        padding: 8px 40px 8px 4px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid rgb(47, 47, 47);
        &.box-shadow {
          // box-shadow: 0px -10px 10px rgb(0, 0, 0, 0.15);
        }
        .clear {
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          // color: rgba(0, 0, 0, 0.6);
        }
        .info {
          display: flex;
          flex-direction: row;
          font-weight: 700;
          font-size: 12px;
          line-height: 14px;
          .right {
            margin-left: 36px;
          }
        }
      }
    }
    .hidden-btn {
      display: none;
      width: 100%;
      padding: 16px 12px;
    }
  }
  &-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    min-width: 320px;
    margin-left: 24px;
    height: max-content;
    padding: 32px 16px;
    padding-top: 8px;
    background: black;
    border-radius: 0px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    .bold {
      font-weight: 600;
    }
    .mini-header {
      flex: 1;
      font-size: 12px;
      text-align: center;
      line-height: 14px;
      &:first-child {
        margin-right: 4px;
      }
      &:last-child {
        margin-left: 4px;
      }
    }
  }
}

.products-price-mobile {
  background-color: transparent;
  border-radius: 0px;
  // padding: 12px 24px;
  padding: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  .info {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    .right {
      flex: 1;
      margin-left: 12px;
    }
  }
}

.delivery {
  &-holder {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
  }
  &-option {
    cursor: pointer;
    flex: 1;
    margin: 0px 8px;
    background: #d9d9d9;
    border-radius: 0px;
    padding: 8px;
    text-align: center;
    color: #000;
    border: 1px solid transparent;
    transition: 0.25s all;
    &-text {
      opacity: 0.6;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.6);
    }
    &.selected {
      border: 1px solid rgba(0, 0, 0, 0.6);
    }
  }
  &-message-btn {
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    background-color: #ffc000;
    border-radius: 0px;
    padding: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

// .add-card {
//   display: none;
//   position: fixed;
//   background-color: #ffc000;
//   color: #000 !important;
//   width: 100%;
//   padding: 10px 32px;
//   bottom: 0;
//   bottom: 60px;
//   left: 0;
//   font-weight: 500;
//   font-size: 18px;
//   z-index: 20;
//   &:disabled {
//     background-color: #856400;
//     opacity: 1;
//     color: rgba(0, 0, 0, 0.3) !important;
//     // color: rgba(0, 0, 0, 0.7);
//   }
// }
.form-input {
  background: black;
  color: #d9d9d9;
  border: 1px solid rgb(47, 47, 47);
  border-radius: 0px;
  padding: 8px 9px;
  margin-bottom: 16px;
  outline: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  &:focus,
  &:focus-visible,
  &:active,
  &:hover {
    opacity: 1 !important;
    // background: #d9d9d9 !important;
    // color: rgba(0, 0, 0) !important;
  }
  &.margin {
    &-right {
      margin-left: 4px;
    }
    &-left {
      margin-right: 4px;
    }
  }
  &.calendar {
    position: relative;
    cursor: pointer;
    text-align: center;
    font-weight: 400;
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }
    &::-webkit-datetime-edit {
      color: #000;
    }
  }
  &.time {
    display: none !important;
    visibility: hidden !important;
  }
  &::placeholder {
    color: rgb(0, 0, 0, 0.5);
  }
  &::-webkit-datetime-edit {
    color: rgb(0, 0, 0, 0.5);
  }
}
.form-textarea {
  resize: none;
}
.form-submit {
  width: 100%;
  padding: 10px 54px;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  background-color: #ffc000 !important;
}
.right-justify {
  text-align: end;
}
.rdrDateDisplayWrapper {
  background-color: transparent;
  .rdrDateDisplay {
    margin: 0;
  }
}
.rdrCalendarWrapper {
  background: transparent;
}
.form-check-input:checked {
  background-color: #000;
  border-color: #000;
}
.btn-input {
  transition: 0.25s;
  &:hover {
    background: #ebebeb !important;
  }
}
.btn-tiny {
  padding: 4px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  width: 100%;
  &.selected {
    background-color: #ebebeb;
  }
}
.btn-0 {
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  width: 100%;
}
.time-picker {
  position: absolute;
  border-radius: 0px;
  height: 120px;
  width: 100%;
  top: 100%;
  left: 0;
  background: #f6f6f5;
  box-shadow: 0px 0px 4px rgb(0, 0, 0, 0.1);
  display: flex;
  &-hour {
    flex: 1;
    padding: 4px;
    display: flex;
    flex-direction: column;
    // gap: 4px;
    height: 120px;
  }
  &-minutes {
    flex: 1;
    padding: 4px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 120px;
    overflow: scroll;
  }
}

.phone-input {
  padding: 0px;
  margin-bottom: 0px;
  border: none;
}

.langs {
  &-holder {
    position: relative;
    width: 56px;
  }
  position: absolute;
  z-index: 1;
  right: 0px;
  border: none;
  outline: none;
  overflow: hidden;
  border-radius: 0px;
  filter: drop-shadow(0px 2px 4px rgba(21, 29, 36, 0.08));
  transition: 0.25s;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 6px 0;

  &.collapsed {
    height: 17px;
    filter: none;
    padding: 0;
    background-color: transparent;
  }

  .lang {
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    outline: none;
    border: none;
    height: 17px;
    display: flex;
    align-items: center;
    background: transparent;
    cursor: pointer;
    img {
      height: 17px;
      object-fit: cover;
    }
    .name {
      font-size: 14px;
      font-weight: 400;
      color: #000;
      margin: 0 6px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .form-submit {
    display: none;
  }
  // .add-card {
  //   display: inherit;
  // }
  .basket {
    &-holder {
      flex-direction: column;
      height: inherit;
    }
    &-content {
      margin-bottom: 24px;
      border-radius: 0px;
      max-height: 100%;
      min-height: 100%;
      .hidden-btn {
        display: inherit;
      }
      .products {
        &-price {
          display: none;
          .clear {
            display: none;
          }
          .info {
            width: 100%;
            display: flex;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            .right {
              flex: 1;
              margin-left: 36px;
            }
          }
        }
      }
    }
    &-action {
      margin-left: 0;
      padding: 24px;
    }
  }
}

@media screen and (min-width: 1921px) {
  .basket {
    &-holder {
      width: 100%;
      display: flex;
      max-width: 1200px;
      height: 100%;
    }
  }
}
