@import "react-date-range/dist/styles.css";
@import "react-date-range/dist/theme/default.css";

.basket {
  &-item {
    &-holder {
      display: flex;
      flex-direction: row;
      padding: 8px 0px;
      align-items: center;
      position: relative;
    }
    &-image {
      width: 60px;
      height: 60px;
      object-fit: contain;
    }
    &-info {
      margin: 0px 8px;
      flex: 1;
      display: flex;
      flex-direction: column;
      text-decoration: none;
      color: rgb(211, 211, 211);
      .name {
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
      }
      .category {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    &-price {
      display: flex;
      flex-direction: column;
      width: 112px !important;
      margin: 0 8px;
      .overall {
        font-weight: 500;
        font-size: 15px;
        line-height: 21px;
        // margin-bottom: 4px;
      }
      .explanation {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    &-count {
      display: flex;
      align-self: center;
      align-items: center;
      .icon {
        border-radius: 24px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 8px;
        padding: 0px;
      }
    }
    &-delete {
      align-self: flex-start;
      width: 16px;
      height: 16px;
    }
    &-discount {
      position: absolute;
      bottom: 8px;
      left: 0;
      padding: 2px 12px;
      background-color: #ffc000;
      border-radius: 8px;
      color: #fff;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .basket {
    &-item {
      &-mobile {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0 0 0 8px;
        &-holder {
          flex: 1;
          display: flex;
          flex-direction: column;
        }
      }
      &-info {
        margin: 0px !important;
        .name {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 4px !important;
        }
        .category {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
      &-count {
        display: flex;
        align-self: center;
        .icon {
          // background-color: #d9d9d9;
          border-radius: 24px;
          width: 32px !important;
          height: 32px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 8px;
          padding: 0px;
          &:first-child {
            margin-left: 0px;
          }
          &:last-child {
            margin-right: 0px;
          }
        }
      }
    }
  }
}
