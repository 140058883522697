footer {
  margin-top: 60px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    a,
    p {
      color: #000 !important;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      margin-block-start: 0em;
      margin-block-end: 8px;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      &:last-child {
        margin: 0;
      }
    }
    .phone {
      font-weight: 500 !important;
      font-size: 24px !important;
      line-height: 29px !important;
      margin-top: 24px !important;
      margin-bottom: 24px !important;
    }
  }
  .social {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    align-items: flex-end;
    .icon-holder {
      display: flex;
      align-items: flex-end;
      margin-bottom: 24px;
      a {
        margin: 0;
        margin-right: 24px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
    p,
    a {
      color: #000 !important;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      margin-block-start: 0em;
      margin-block-end: 8px;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      &:last-child {
        margin: 0;
      }
    }
  }
  &.breakpoint {
  }
}

@media screen and (max-width: 1024px) {
  footer {
    &.breakpoint {
      display: none;
    }
  }
}
