.category {
  &-holder {
    width: calc(100% + 40px);
    margin-left: -20px;
    padding-left: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &-item {
    width: max-content;
    margin-right: 12px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    padding: 12px;
    background-color: #f6f6f6;
    border-radius: 12px;
    width: max-content;
    .icon {
      width: 24px;
      height: 20px;
      object-fit: contain;
      margin-right: 12px;
      filter: invert(1);
    }
    .category {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #000;
    }
  }
}

.category-product {
  cursor: pointer;
  padding: 16px;
  border-radius: 12px;
  &-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }
  .info {
    margin: 0 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    &-holder {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    &-icon {
      min-width: 60px;
      max-width: 60px;
      height: 60px;
      object-fit: contain;
    }
    &-price {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      min-width: 60px;
      text-align: end;
    }
    &-services {
      font-weight: 400;
      font-size: 10px;
      line-height: 12px;
      display: flex;
      align-items: center;
      text-align: left;
      max-width: calc(100% - 100px);
      color: rgba(0, 0, 0, 0.8);
    }
  }
  .set {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    position: absolute;
    background-color: #ffc000;
    border-radius: 100px 32px 32px 8px / 60px 32px 32px 8px;
    padding: 10px 24px;
    bottom: -24px;
    right: 0;
  }
}

@media screen and (max-width: 1024px) {
  .category {
    &-item {
      cursor: pointer;
      border: 1px solid transparent;
      &.selected {
        border: 1px solid #ffc000;
      }
    }
  }
  .category-product {
    background-color: #f6f6f5;
    position: relative;
    &-list {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 12px;
      grid-row-gap: 12px;
    }
    .info {
      margin: 0 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      &-holder {
        position: static;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
    .set {
      bottom: 0;
    }
  }
}

@media screen and (max-width: 1800px) and (min-width: 1024px) {
}

@media screen and (min-width: 2560px) {
  .category-product {
    &-list {
      grid-template-columns: repeat(6, 1fr);
    }
  }
}
