.mobile-menu {
  width: 100%;
  &-item {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    margin-bottom: 12px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: rgb(211, 211, 211) !important;
    border-radius: 12px;
    background-color: transparent !important;
    text-decoration: none;
    .icon {
      width: 24px;
      height: 24px;
      margin-right: 20px;
      filter: brightness(0%);
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
@media screen and (max-width: 1024px) {
}
