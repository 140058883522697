.lang-switcher {
  display: flex;
  padding: 16px 24px;
  a {
    transition: 0.5s all;
    cursor: pointer;
    color: rgb(211, 211, 211);
    text-decoration: none;
    position: relative;
    &:hover {
      color: #ffc000;
    }
    &:last-child {
      // margin-left: 16px;
    }
    &.active {
      color: #fff;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        left: 0;
        top: calc(100% + 2px);
        // border-bottom: solid 1px #fff;
      }
    }
    &::after {
      content: "";
      transition: 0.5s all;
    }
  }
}

@media screen and (max-width: 1024px) {
  .lang-switcher {
    padding: 16px 0px;
    a {
      &:last-child {
        // margin-left: 12px;
      }
    }
  }
}
