@import "variables";

// @font-face {
//   font-family: '#{$icomoon-font-family}';
//     url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?fcdvev') format('truetype'),
//   font-weight: normal;
//   font-style: normal;
//   font-display: block;
// }

[class^="penumbra-"],
[class*=" penumbra-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.penumbra-add {
  &:before {
    content: $penumbra-add;
  }
}
.penumbra-bag {
  &:before {
    content: $penumbra-bag;
  }
}
.penumbra-category {
  &:before {
    content: $penumbra-category;
  }
}
.penumbra-chevron-down {
  &:before {
    content: $penumbra-chevron-down;
  }
}
.penumbra-chevron-left {
  &:before {
    content: $penumbra-chevron-left;
  }
}
.penumbra-chevron-right {
  &:before {
    content: $penumbra-chevron-right;
  }
}
.penumbra-chevron-up {
  &:before {
    content: $penumbra-chevron-up;
  }
}
.penumbra-date {
  &:before {
    content: $penumbra-date;
  }
}
.penumbra-home {
  &:before {
    content: $penumbra-home;
  }
}
.penumbra-instagram {
  &:before {
    content: $penumbra-instagram;
  }
}
.penumbra-location {
  &:before {
    content: $penumbra-location;
  }
}
.penumbra-menu {
  &:before {
    content: $penumbra-menu;
  }
}
.penumbra-minus {
  &:before {
    content: $penumbra-minus;
  }
}
.penumbra-phone {
  &:before {
    content: $penumbra-phone;
  }
}
.penumbra-question {
  &:before {
    content: $penumbra-question;
  }
}
.penumbra-remove {
  &:before {
    content: $penumbra-remove;
  }
}
.penumbra-search {
  &:before {
    content: $penumbra-search;
  }
}
.penumbra-start {
  &:before {
    content: $penumbra-start;
  }
}
.penumbra-telegram {
  &:before {
    content: $penumbra-telegram;
  }
}
.penumbra-trash {
  &:before {
    content: $penumbra-trash;
  }
}
.penumbra-whatsapp {
  &:before {
    content: $penumbra-whatsapp;
  }
}
