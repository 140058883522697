.wrapper {
  position: relative;
  text-align: left;
  background-color: #000000;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  .right-side {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    min-width: 0;
    overflow-y: scroll;
    background-color: #fff;
    // border-radius: 48px 0 0 0;
    padding: 60px 60px 32px 60px;
    align-self: stretch;
    flex-grow: 1;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    .content {
      flex: 1;
      width: 100%;
      &-holder {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 120px);
      }
      .basket-outer {
        position: fixed;
        z-index: 100;
        bottom: 0;
        right: 0;
      }
      .basket-link {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        background-color: #1f1f1f;
        width: 80px;
        // border-bottom-left-radius: 80px;
        text-decoration: none;
        &-count {
          position: absolute;
          right: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          color: #000;
          background-color: #ffc000;
          font-weight: 500;
          font-size: 8px;
          line-height: 10px;
        }
      }
    }
  }
}

.side {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateX(100%);
  z-index: 9999;
  transition: 0.3s all;
  &.hidden {
    width: 0;
  }
  &-outer {
    position: absolute;
    width: calc(100% - 264px);
    left: 0;
    height: 100%;
  }
  &-menu {
    position: absolute;
    right: 0;
    padding-top: 60px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    width: 264px;
    height: 100%;
    background-color: #0c0b15;
  }
}

@media screen and (max-width: 1024px) {
  .side {
    display: inherit;
  }
  .wrapper {
    display: inherit;
    .right-side {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 32px 20px;
      padding-bottom: 64px;
      .content {
        &-holder {
          min-height: max-content;
        }
        .basket-outer {
          display: none;
        }
      }
    }
  }
}

.animate {
  animation-duration: 0.5s;
  animation-name: animate-bounce;
  animation-delay: 0.25s;
  animation-fill-mode: backwards;
}

@keyframes animate-bounce {
  0% {
    top: -80px;
    right: -80px;
  }
  30% {
    top: -5px;
    right: -5px;
  }
  60% {
    top: -12px;
    right: -12px;
  }
  100% {
    top: 0px;
    right: 0px;
  }
}

.animate-jump {
  animation-name: jump;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-delay: 0;
}

@keyframes jump {
  0%,
  5%,
  10% {
    transform: translate3d(0, 0, 0);
  }

  2.5%,
  7.5% {
    transform: translate3d(0, -5px, 0);
  }
}

.slide-in {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.slide-out {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  width: 0px !important;
}

.white-space {
  white-space: nowrap;
}
