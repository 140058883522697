$icomoon-font-family: "penumbra-icon" !default;
$icomoon-font-path: "fonts" !default;

$penumbra-add: "\e900";
$penumbra-bag: "\e93a";
$penumbra-category: "\e902";
$penumbra-chevron-down: "\e903";
$penumbra-chevron-left: "\e904";
$penumbra-chevron-right: "\e905";
$penumbra-chevron-up: "\e906";
$penumbra-date: "\e907";
$penumbra-home: "\e908";
$penumbra-instagram: "\e909";
$penumbra-location: "\e90a";
$penumbra-menu: "\e90b";
$penumbra-minus: "\e90c";
$penumbra-phone: "\e90d";
$penumbra-question: "\e90e";
$penumbra-remove: "\e90f";
$penumbra-search: "\e910";
$penumbra-start: "\e911";
$penumbra-telegram: "\e912";
$penumbra-trash: "\e913";
$penumbra-whatsapp: "\e914";
