aside {
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 360px !important;
  height: 100vh !important;
  overflow-y: scroll;
  color: #fff;
  padding: 48px 0px 48px 24px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  .spinner-holder {
    width: 100%;
    height: 100%;
  }
  .menu {
    width: 100%;
    margin-top: 6px;
    &-item {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px 24px;
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #fff;
      text-decoration: none;
      .icon {
        width: 24px;
        height: 24px;
        margin-right: 20px;
      }
      &:last-child {
        // margin-bottom: 0px;
      }
      &:hover {
        text-decoration: underline;
      }
      &.active {
        text-decoration: underline;
        color: #ffff00;
        outline: none;
        position: relative;
        // background-color: #fff;
        // border-radius: 24px 0 0 24px;
        .icon {
          filter: brightness(0%);
        }
        &::after {
          content: "";
          position: absolute;
          background-color: transparent;
          bottom: 100%;
          right: 0;
          height: 56px;
          width: 56px;
          // border-bottom-right-radius: 24px;
          // box-shadow: 0 24px 0 0 #fff;
        }
        &::before {
          content: "";
          position: absolute;
          background-color: transparent;
          top: 56px;
          right: 0;
          height: 56px;
          width: 56px;
          // border-top-right-radius: 24px;
          // box-shadow: 0 -24px 0 0 #fff;
        }
      }
    }
  }
  .request-phone {
    width: calc(100% - 64px);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #ffc000;
    border-radius: 12px;
    font-size: 18px;
    margin: 12px 24px;
    padding: 12px 24px;
    color: #000000;
    &:active,
    &:focus,
    &:hover,
    &:focus-visible,
    &:focus-within {
      color: #000000;
      opacity: 1;
    }
    img {
      margin-right: 12px;
    }
  }
}

.phone-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000000 !important;
  .text {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
  .number {
    margin-top: 21px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }
  .address {
    margin-top: 21px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
  }
  .social-holder {
    display: flex;
    align-items: flex-end;
    margin-top: 21px;
    a {
      color: #000000 !important;
      font-size: 24px;
      margin: 0;
      margin-right: 24px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  aside {
    display: none;
  }
}
