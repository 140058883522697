.countdown {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
}
.message {
  flex: 1;
}
.delete {
  margin-left: 12px !important;
}
.basket-item-holder-delete {
  width: 100%;
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 1024px) {
  .basket-item-holder-delete {
    flex-direction: column !important;
  }
}
