.product-list-holder {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 248px;
  grid-column-gap: 40px;
  grid-row-gap: 48px;
}

.search {
  &-holder {
    position: relative;
    max-width: 360px;
    padding: 0 16px 0 12px;
    justify-content: space-between;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    background-color: #f6f6f5;
    margin-bottom: 24px;
    align-items: center;
  }
  &-input {
    flex: 1;
    padding: 12px 0;
    outline: none;
    border: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  &-image {
    width: 12px;
    height: 12px;
  }
  &-results {
    position: absolute;
    top: calc(100% + 12px);
    left: 0;
    width: 100%;
    z-index: 1000;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f6f6f5;
    border-radius: 12px;
    max-height: 50vh;
    overflow-y: scroll;
    &-list {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    &-item {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      width: 100%;
      position: relative;
      &-price {
        &-holder {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
        &.discount {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          opacity: 0.6;
          text-decoration-line: line-through;
        }
        &.new {
          font-weight: 400;
          color: #ffc000;
          font-size: 12px;
          line-height: 18px;
          opacity: 0.6;
        }
      }
      &-discount {
        position: absolute;
        bottom: 8px;
        left: 0;
        padding: 2px 16px;
        background-color: #ffc000;
        border-radius: 8px;
        color: #fff;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
      }
      &-image {
        min-width: 64px;
        width: 64px;
        height: 64px;
        object-fit: contain;
      }
      padding: 8px 0;
      // border-bottom: 0.5px solid #dadada;
      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.add-card {
  display: none;
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 10px 32px;
  bottom: 60px;
  left: 0;
  z-index: 20;
  font-weight: 500;
  font-size: 18px;
}

.selected-category {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  margin-bottom: 20px;
  background: #f6f6f5;
  border: 1px solid #ffc000;
  border-radius: 12px;
  width: max-content;
  img {
    height: 20px;
    width: 20px;
    object-fit: contain;
    filter: invert(1);
  }
  span {
    margin-left: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #000;
  }
}

.search-border {
  border: 1px solid transparent;
  &:has(.search-input) {
    &:hover,
    &:active,
    &:focus-within,
    &:focus-visible,
    &:target,
    &:visited,
    &:focus {
      border: 1px solid #ffc000;
    }
  }
}

.product-pagination {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  align-content: flex-end;
  flex: 1;
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    background-color: black;
    border-radius: 50%;
    padding: 10px 16px;
    max-width: 40px;
    max-height: 40px;
    border: none !important;
    &.left {
      margin-right: 16px;
    }
  }
}

.page {
  &-holder {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }
  &-item {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    margin-right: 24px;
    background: #f6f6f5;
    border-radius: 12px;
    width: 268px;
    &:last-child {
      margin-right: 0;
    }
    &-icon {
      width: 36px;
      height: 36px;
      object-fit: contain;
    }
    &-name {
      margin-top: 0px;
      margin-left: 16px;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .product-list-holder {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 288px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  .search {
    &-holder {
      max-width: 100%;
      margin-bottom: 20px;
    }
  }
  .page {
    &-holder {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      margin-bottom: 0px;
      width: calc(100% + 40px);
      padding: 0 20px;
      margin-left: -20px;
      border-top: 8px solid #f6f6f6;
    }
    &-item {
      width: max-content;
      color: #000 !important;
      flex-direction: column;
      padding: 18px 12px;
      margin-right: 0;
      background: transparent;
      min-width: 100px;
      &-name {
        margin-top: 12px;
        margin-left: 0px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}

@media screen and (max-width: 1800px) and (min-width: 1024px) {
  .product-list-holder {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 248px;
    grid-column-gap: 40px;
    grid-row-gap: 48px;
  }
}

@media screen and (min-width: 2560px) {
  .product-list-holder {
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 288px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}
