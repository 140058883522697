.product {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 1200px;
  &-images {
    max-width: calc(100% - 400px);
    max-height: 400px;
    display: flex;
    justify-content: flex-start;

    .virtual-swiper {
      width: 122px;
      margin: 0px !important;
      .image {
        &-holder {
          width: 120px;
          height: 100%;
          border-radius: 12px;
          background-color: #f6f6f5;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .swiper-slide {
        transition: 0.25s all;
        border-radius: 12px;
        overflow: hidden;
        opacity: 0.7;
      }
      .thumb-active {
        opacity: 1;
      }
    }

    .main-swiper {
      flex: 1;
      margin-left: 16px;
      height: 100%;
      border-radius: 12px;
      .swiper-wrapper {
        width: 100%;
        height: 100%;
      }
      .image {
        &-holder {
          width: 100%;
          height: 400px;
          border-radius: 12px;
          background-color: #f6f6f5;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
  &-info {
    margin-left: 32px;
    width: 100%;
    height: 100%;
    &-required {
      flex: 1;
      img {
        width: 16px;
        height: 16px;
        object-fit: cover;
      }
    }
    h1 {
      margin-top: 0px;
      font-weight: 500;
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 8px;
    }
    .articule {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
    .card-add {
      display: flex;
      flex-direction: row;
      align-items: center;
      .count {
        display: flex;
        flex-direction: row;
        align-items: center;
        &-amount {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 36px;
          height: 36px;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          background-color: #d9d9d9;
          border-radius: 18px;
        }
        .icon {
          img {
            width: 12px;
            height: 12px;
          }
        }
      }
      .add {
        // margin-left: 36px;
        padding: 12px 36px;
      }
    }
    .description {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      &-list {
        margin-left: 12px;
        a {
          color: #000;
          text-decoration: none;
          &:hover {
            color: #ffc000;
          }
        }
      }
    }
  }
  &-discount {
    padding: 6px 24px;
    background-color: #ffc000;
    border-radius: 12px;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    margin-left: 16px;
    margin-bottom: 8px;
  }
  .line-througth {
    margin-left: 4px;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    opacity: 0.6;
    text-decoration-line: line-through;
  }
}

.sections {
  display: flex;
  flex-direction: row;
  .btn {
    border-radius: 12px;
    padding: 10px 36px;
    padding-left: 0px !important;
    &.active {
      border: 1px solid #000000 !important;
      background-color: transparent !important;
      &:focus,
      &:active,
      &:hover {
        border: 1px solid #000000 !important;
        background-color: transparent !important;
        color: rgb(211, 211, 211) !important;
      }
    }
    &-gray {
      border: 1px solid transparent !important;
      &:hover {
        border: 1px solid #000000 !important;
        color: rgb(211, 211, 211) !important;
      }
    }
    &:first-child {
      margin-right: 16px;
    }
  }
}
.video {
  &-iframe {
    border-radius: 10px;
  }
}
.modal-custom {
  width: 100%;
  background-color: transparent !important;
  &-remove {
    position: absolute;
    top: -24px;
    right: -24px;
    color: #fff;
    font-size: 24px;
    transform: rotate(45deg);
  }
}
.carousel-swiper {
  width: 100%;
  height: calc(100vh - 120px);
  .modal-photo {
    width: 100%;
    height: calc(100vh - 120px);
    object-fit: contain;
  }
}

@media screen and (max-width: 1024px) {
  .product {
    flex-direction: column;
    &-images {
      max-width: 100%;
      max-height: 320px;
      .virtual-swiper {
        display: none;
      }
      .main-swiper {
        margin-left: 0;
        .image {
          &-holder {
            height: 320px;
          }
        }
      }
    }
    &-info {
      margin-top: 20px;
      margin-left: 0;
      h1 {
        font-size: 24px;
        line-height: 28px;
      }
    }
    .line-througth {
      line-height: 22px;
    }
  }
  .carousel-swiper {
    width: 100%;
    height: calc(100vh - 240px);
    .modal-photo {
      width: 100%;
      height: calc(100vh - 240px);
      object-fit: contain;
    }
  }
  .sections {
    margin-left: -20px;
    width: calc(100% + 40px);
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    .btn {
      min-width: max-content;
      &:first-child {
        margin-left: 20px;
      }
      &:last-child {
        margin-right: 20px;
      }
    }
  }
}
