.navigation {
  display: none;
}

.basket-count {
  position: absolute;
  right: -7px;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  border-radius: 0px !important;
  color: #000;
  background-color: #ffc000;
  font-weight: 500;
  font-size: 8px;
  line-height: 10px;
}

@media screen and (max-width: 1024px) {
  .navigation {
    display: flex;
    align-items: center;
    padding: 12px 32px 18px;
    height: 60px;
    flex-direction: row;
    justify-content: space-between;
    &-item {
      min-width: 52px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      &:active,
      &:hover,
      &:focus {
        color: #fff;
      }
      .icon {
        font-size: 22px;
      }
      .name {
        margin-top: 6px;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
      }
      &.active {
        color: #ffc000;
      }
    }
  }
}
