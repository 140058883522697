@import "react-date-range/dist/styles.css";
@import "react-date-range/dist/theme/default.css";

.basket {
  &-item {
    &-holder {
      display: flex;
      flex-direction: row;
      padding: 8px 0px;
      align-items: center;
    }
    &-image {
      width: 60px;
      height: 60px;
      object-fit: contain;
    }
    &-info {
      margin: 0px 8px;
      flex: 1;
      display: flex;
      flex-direction: column;
      text-decoration: none;
      color: rgb(211, 211, 211);
      .name {
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
      }
      .category {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    &-price {
      display: flex;
      flex-direction: column;
      width: 112px !important;
      margin: 0 8px;
      .overall {
        font-weight: 500;
        font-size: 15px;
        line-height: 21px;
        // margin-bottom: 4px;
      }
      .explanation {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
    &-count {
      display: flex;
      align-self: center;
      .icon {
        border-radius: 24px;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 8px;
        padding: 0px;
      }
    }
    &-delete {
      align-self: flex-start;
      width: 16px;
      height: 16px;
    }
  }
}
