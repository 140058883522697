.thankPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
  .header {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 22px !important;
  }
  .manager-call {
    font-size: 14px !important;
    line-height: 24px !important;
    margin-bottom: 16px;
    font-weight: 400 !important;
  }
  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
  }
}
