.product-item {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 0 16px;
  border-radius: 0px;
  background-color: #f6f6f5;
  align-items: center;
  align-content: center;
  overflow: hidden;
  height: 248px;
  &-border {
    border: 2px solid #ffc000;
  }
  a {
    padding: 32px;
    color: #000;
    text-decoration: none;
    height: 100%;
    width: 100%;
  }
  .image {
    width: 100%;
    height: 120px;
    object-fit: contain;
  }
  .info {
    &-holder {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-top: 12px;
    }
    &-name {
      &-holder {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 42px;
      }
      margin: 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
    }
    &-price {
      margin-bottom: 0px;
      margin-top: 6px;
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      &.bold {
        font-weight: 500;
      }
      &.dicount {
        margin-left: 4px;
        font-weight: 400;
        font-size: 12px;
        opacity: 0.6;
        text-decoration-line: line-through;
      }
      &.new {
        margin-left: 4px;
        font-weight: 400;
        font-size: 12px;
        color: #ffc000;
      }
    }
    &-btn {
      display: none;
      margin-top: -32px;
      padding: 4px 16px !important;
      overflow: hidden;
    }
  }
  .complekt {
    // position: absolute;
    // top: 0;
    // left: 0;
    // background: #ffc000;
    padding: 6px 24px;
    border-radius: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .discount-percent {
    position: absolute;
    top: 0;
    right: 0;
    background: #ffc000;
    color: #fff;
    padding: 6px 32px 6px 24px;
    border-radius: 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  &.active {
    height: 288px;
    z-index: 10;
    box-shadow: 0px 4px 16px -10px rgba(0, 0, 0, 0.1);
    .info {
      &-btn {
        display: inherit;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .product-item {
    padding: 0 0 16px;
    border-radius: 28px;
    a {
      padding: 32px 8px 16px 8px;
    }
    .image {
      width: 100%;
      height: 130px;
      object-fit: contain;
    }
    &-border {
      height: 252px;
      border: 2px solid #ffc000;
      .image {
        height: 126px;
      }
    }
    .info {
      &-name {
        &-holder {
          height: 34px;
        }
        font-size: 14px;
        line-height: 17px;
      }
      &-price {
        font-size: 14px;
        line-height: 17px;
      }
      &-btn {
        height: 34px;
        font-size: 12px;
        padding-left: 8px !important;
        padding-right: 8px !important;
      }
    }
    .discount-percent {
      padding: 6px 12px 6px 12px;
      border-radius: 6px 12px;
    }
    .complekt {
      padding: 6px 16px;
      border-radius: 12px 6px;
    }
  }
}

.animation-opacity {
  animation-duration: 0.5s;
  animation-name: opacity-animation;
  animation-delay: 0s;
  animation-fill-mode: forwards;
}

@keyframes opacity-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
