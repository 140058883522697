.specification {
  padding: 24px;
  background-color: #f6f6f5;
  border-radius: 48px;
  max-width: 1200px;
  &-table {
    display: flex;
    flex-direction: row;
    gap: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    .attributes {
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 1024px) {
  .specification {
    margin-bottom: 64px;
    overflow-x: scroll;
    .attributes {
      flex: 1;
      font-weight: 600;
    }
    .values {
      flex: 1;
    }
  }
}
