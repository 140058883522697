.mobile-footer {
  color: #fff !important;
  .pages {
    &-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: #fff !important;
      margin-top: 12px;
      img {
        width: 16px;
        height: 16px;
      }
      span {
        margin-left: 12px;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
  .mobile-icon-holder {
    display: flex;
    align-items: flex-end;
    margin-top: 24px;
    a {
      font-size: 24px;
      margin: 0;
      color: #fff;
      margin-right: 24px;
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}

.mobile-contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #fff !important;
  a,
  p {
    color: #fff !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-block-start: 0em;
    margin-block-end: 8px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    &:last-child {
      margin: 0;
    }
  }
  .phone {
    font-weight: 500 !important;
    font-size: 18px !important;
    line-height: 21px !important;
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
}
