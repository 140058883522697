@import "~bootstrap/scss/bootstrap";
@import "./icons/style.scss";

body {
  overflow: hidden;
  margin: 0;
  width: 100vw !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.modal {
  --bs-modal-border-width: 0;
  --bs-modal-border-radius: 48px;
  --bs-modal-padding: 32px;
}

:root {
  --bs-link-color: #ffc000;
  --swiper-navigation-color: #fff;
  --swiper-theme-color: #fff;
  --swiper-pagination-color: #ffc000;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 1;
  --app-height: 100%;
  touch-action: pan-x pan-y;
  height: 100vw;
}

@media screen and (max-width: 1024px) {
  .modal {
    --bs-modal-border-width: 0;
    --bs-modal-border-radius: 16px;
    --bs-modal-padding: 24px 12px;
  }
}

a {
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: rgba(0, 0, 0, 0.7);
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  align-content: center;
  padding: 8px 16px;
  transition: 0.25s all;
  color: #000;
  border: none;
  &:focus,
  &:active,
  &:hover {
    outline: none !important;
    box-shadow: none !important;
    color: rgb(211, 211, 211) !important;
  }
  &-black {
    background-color: #000 !important;
    color: #fff !important;
    &-outline {
      background-color: transparent !important;
      color: #000 !important;
      border: 1px solid #000000 !important;
      &:focus,
      &:active,
      &:hover {
        color: #000 !important;
        border: 1px solid #000000 !important;
      }
    }
    &:focus,
    &:active,
    &:hover {
      color: #fff !important;
    }
  }
  &-white {
    background-color: #fff !important;
    color: #000 !important;
    &-outline {
      background-color: #fff !important;
      color: #000 !important;
      border: 1px solid #000000 !important;
      &:focus,
      &:active,
      &:hover {
        color: #000 !important;
        border: 1px solid #000000 !important;
      }
    }
    &:focus,
    &:active,
    &:hover {
      color: #000 !important;
    }
  }
  &-gray {
    background-color: #f6f6f5 !important;
    color: #000 !important;
  }
  &:disabled {
    opacity: 0.3;
  }
}

.overflow {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &-2 {
    -webkit-line-clamp: 2;
  }
}

.animate-fade {
  animation-duration: 0.5s;
  animation-name: animate-fade;
  animation-delay: 0s;
  animation-fill-mode: backwards;
}

@keyframes animate-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.app-spinner-holder {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
