.header {
  display: none;
}

@media screen and (max-width: 1024px) {
  .header {
    display: flex;
    height: 60px;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .icon {
      height: 20px;
      object-fit: contain;
    }
  }
}
